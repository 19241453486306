import * as React from "react"
import { useEffect } from 'react';
import { Link } from "gatsby"

import Layout from "../../../templates/layout-standard"
import Seo from "../../../components/seo"
import SideBar from '../../../components/sidebar';
import Turning65Nav from '../../../components/nav/Turning65';
import DidYouKnow from '../../../components/ui/DidYouKnow';
import LearnMore from '../../../components/learnmore';
import SpeakWithAdvisor from '../../../components/ui/SpeakWithAdvisor';

function toggleAccordion(event) {
	let accordionContainer = this.nextElementSibling;
	this.classList.toggle("open");
	if (this.classList.contains('open')) {
		this.setAttribute("aria-hidden", "false");
	} else {
		this.setAttribute("aria-hidden", "true");
	}

	if (!accordionContainer.classList.contains('open')) {
		accordionContainer.classList.add('open');
		accordionContainer.style.height = 'auto';
	
		let height = accordionContainer.clientHeight + 'px';
	
		accordionContainer.style.height = '0px';
	
		setTimeout(function () {
			accordionContainer.style.height = height;
		}, 0);
	} else {
		accordionContainer.style.height = '0px';		
		accordionContainer.addEventListener('transitionend', function () {
			accordionContainer.classList.remove('open');
		}, {
			once: true
		});
	}
}

const Turning65Tips = ({ location }) => {
	useEffect(() => {
		document.querySelectorAll(".accordiontrigger").forEach(trigger => {
			trigger.removeEventListener('click', toggleAccordion);
			trigger.addEventListener('click', toggleAccordion);
		});
	});

	return (
		<Layout location={location} heading1="Turning 65" heading2="Let&rsquo;s get you ready for Medicare.">
		<Seo title="Tips For Your Situation | Turning 65 | Medicare Basics" meta={[{description: 'Learn how to make the most of the various Medicare plan options available to you.'}]} bodyclass="medicare-basics section1 subpage" />
			<Turning65Nav />
				
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">

						<main>
							<h3>Tips for Your Situation </h3>
							
							<p>Turning 65 opens the door to new health insurance options, but everyone&rsquo;s situation is different.</p>

							<p><b>Select the circumstance that best applies to your current situation:</b></p>

							<div className="accordion">
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-01" className="accordiontrigger" aria-controls="accordion-content-01">
										If you&rsquo;re going to keep your (or your spouse&rsquo;s) current employer coverage:
									</button>
									<div id="accordion-content-01" className="accordion-content" aria-labelledby="accordion-trigger-01" aria-hidden="true">
										<div className="contentwrap">
											<ul>
												<li>Find out if you need to enroll in Part B&mdash;it may be necessary if your employer has fewer than 20 employees, to avoid penalties.</li>
												<li>Ask your employer if your plan&rsquo;s prescription drug coverage is as good as Medicare&rsquo;s coverage (it&rsquo;s called &ldquo;creditable coverage&rdquo;). If not, enroll in Part D to avoid penalties.</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-01" className="accordiontrigger" aria-controls="accordion-content-01">
										If you&rsquo;re NOT keeping your current employer coverage:
									</button>
									<div id="accordion-content-01" className="accordion-content" aria-labelledby="accordion-trigger-01" aria-hidden="true">
										<div className="contentwrap">
											<ul>
												<li>Talk to the employer to see if there are other options or plans available to people 65 and older.</li>
												<li>Enroll in Parts A and B once you&rsquo;re eligible.</li>
												<li>Consider Part C (Medicare Advantage) or supplemental plans for additional coverage. Medicare Advantage plans often feature no deductibles on medical services, and offer benefits that go above and beyond Part A and Part B coverage.</li>
												<li>Sign up for Part D prescription drug coverage. Many Medicare Advantage plans are offered with Part D drug coverage included.</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-01" className="accordiontrigger" aria-controls="accordion-content-01">
										What if your current coverage wasn&rsquo;t purchased through an employer?
									</button>
									<div id="accordion-content-01" className="accordion-content" aria-labelledby="accordion-trigger-01" aria-hidden="true">
										<div className="contentwrap">
											<ul>
												<li>For all other qualified health plans, you will need to enroll in Medicare Part A and Part B, and have a prescription drug coverage that&rsquo;s as good as Medicare&rsquo;s standard plan (or enroll in Part D) to avoid penalties. A Medicare Advantage plan is a great way to get all of these parts of Medicare in one plan. If you choose to keep your current coverage once you turn 65, you will lose any premium tax credits or other savings you are currently receiving. Since you will still need to enroll in Medicare Part A and B, a Medicare Advantage plan may be a better alternative.</li>
												<li>If you have an Essential Plan, your plan will end at age 65 and you will need to enroll in Medicare for health coverage.</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<DidYouKnow>If you don&rsquo;t sign up for Part B when first eligible, or maintain creditable prescription drug coverage, you may find yourself paying multiple penalties. Let our Medicare Plan Guides help you avoid potential problems.</DidYouKnow>
							<LearnMore />
							<SpeakWithAdvisor />
						</main>
						
						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Turning65Tips
